<template>
    <!-- DISCLAIMER INVERSION AUTOMATICA -->
    <vx-card class="mt-5">
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="flex">
            <feather-icon icon="InfoIcon" svgClasses="w-8 h-8 text-primary" class="mr-3" />
            <span class="bold mt-1 italic">
              El algoritmo que distribuye los fondos de la inversión automática busca maximizar la diversificación del portafolio, 
              de tal manera que se alcance un punto óptimo de la relación "riesgo-retorno". <br>Conoce más 
              <a href="https://soporte.redgirasol.com/support/solutions/articles/48001246946--c%C3%B3mo-funciona-el-algoritmo-de-la-inversion-automatica-" class="underline bold" target="blank">aquí.</a>
            </span>
          </div>
        </div>
      </div>
    </vx-card>
  </template>
  
  <script>
  export default{
    name: 'AutomaticInvestmentDisclaimer',
    data() {
      return {
      }
    }
  }
  </script>
