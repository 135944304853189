<template>
    <div>
      <big-title>
        Activar Ingreso <span>Pasivo</span>.
      </big-title>
      <vx-card title="">
        <div class="vx-row">
          <div class="vx-col xxl:w-3/4 w-full">
            <h2>¿Deseas programar un retiro recurrente?</h2>
            <p>Es muy fácil, solo indica el día y cuenta de banco a la que te gustaría retirar automáticamente tu ingreso pasivo. </p>
            <div class="flex mt-5">
              <vs-button class="mt-2" color="dark" type="border" @click.stop="goToInvestmentsPlansMainPage">No,
                continuar sin retiro recurrente</vs-button>
              <vs-button class="ml-6 mt-2" @click.stop="goToRecurrentWithdrawalPage">Activar retiro recurrente</vs-button>
            </div>
          </div>
        </div>
      </vx-card>
      
      <!-- DISCLAIMER INVERSION AUTOMATICA -->
      <automatic-investment-disclaimer/>
    </div>
  </template>
    
  <script>
  import AutomaticInvestmentDisclaimer from "@components/investor/widgets/AutomaticInvestmentDisclaimer";
  export default {
    name: "AskRecurrentWithdrawal",
    components: {
      AutomaticInvestmentDisclaimer
    },
    data() {
      return {
        investorPlan: {
          id: null,
          card_id: null,
          card_amount: null,
          reinvestment_percentage: null,
          qualifications: null,
          monthly_day: null,
          available_money: null,
          retry: null,
          min_autoinvest_project_amount_mxn: null,
          max_autoinvest_project_amount_mxn: null,
          autoinvest_applicant_payments: null,
          autoinvest_deposits: null,
        },
  
        factorEnabled: null,
        planWasActivated: false,
        cancelWarningPopupActive: false,
        isMounted: false,
      }
    },
    methods: {
      async goToRecurrentWithdrawalPage() {
        await this.$router.replace({ name: 'retiroRecurrente' });
      },
      async goToInvestmentsPlansMainPage() {
        await this.$router.replace({ name: 'planesInversion' });
      }
    }
  }
  </script>
  